/*
 * @Author: QiTianDaSheng
 * @Date: 2023-02-28 17:35:03
 * @Descripttion:
 * @LastEditors: GaoHaHa
 * @LastEditTime: 2024-01-08 13:42:52
 */
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        component: () => import('../pages/index/index.vue'),
        meta: {
            title: '开票信息',
        },
    },
    {
        path: '/taxTray/index',
        component: () => import('../pages/taxTray/index.vue'),
        meta: {
            title: '开票信息',
        },
        children: [
            {
                path: '/taxTray/invoicingInfo',
                component: () => import('../pages/taxTray/invoicingInfo.vue'),
                meta: {
                    title: '开票信息',
                },
            },
            {
                path: '/taxTray/goodsInfo',
                component: () => import('../pages/taxTray/goodsInfo.vue'),
                meta: {
                    title: '商品明细',
                },
            },
            {
                path: '/taxTray/billInfo',
                component: () => import('../pages/taxTray/billInfo.vue'),
                meta: {
                    title: '已过期',
                },
            },
            {
                path: '/taxTray/apply',
                component: () => import('../pages/taxTray/apply.vue'),
                meta: {
                    title: '开票中',
                },
            },
            {
                path: '/taxTray/succeed',
                component: () => import('../pages/taxTray/succeed.vue'),
                meta: {
                    title: '开票成功',
                },
            },
        ],
    },
    {
        path: '/digital/index',
        component: () => import('../pages/digital/index.vue'),
        meta: {
            title: '开票信息',
        },
        children: [
            {
                path: '/digital/invoicingInfo',
                component: () => import('../pages/digital/invoicingInfo.vue'),
                meta: {
                    title: '开票信息',
                },
            },
            {
                path: '/digital/goodsInfo',
                component: () => import('../pages/digital/goodsInfo.vue'),
                meta: {
                    title: '商品明细',
                },
            },
            {
                path: '/digital/billInfo',
                component: () => import('../pages/digital/billInfo.vue'),
                meta: {
                    title: '已过期',
                },
            },
            {
                path: '/digital/apply',
                component: () => import('../pages/digital/apply.vue'),
                meta: {
                    title: '开票中',
                },
            },
            {
                path: '/digital/succeed',
                component: () => import('../pages/digital/succeed.vue'),
                meta: {
                    title: '开票成功',
                },
            },
            {
                path: '/digital/abnormal',
                component: () => import('../pages/digital/abnormal.vue'),
                meta: {
                    title: '开票异常',
                },
            },
            {
                path: '/digital/errorMiddlePage',
                component: () => import('../pages/digital/errorMiddlePage.vue'),
                meta: {
                    title: '申请开票',
                },
            },
        ],
    },
]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes,
})

export default router
