/*
 * @Author: QiTianDaSheng
 * @Date: 2023-02-28 17:35:03
 * @Descripttion: 
 * @LastEditors: QiTianDaSheng
 * @LastEditTime: 2023-08-17 10:26:07
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// 全局引入element-ui
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

// 引入方法 - 禁止重复点击
import preventReClick from './utils/preventRepeatClick';
Vue.use(preventReClick)

// 引入微信
import wx from 'weixin-js-sdk'
// let wx = window.wx;// index.html中引入外部js，获取js暴露的wx
// console.log(wx);

// 全局引入vant框架
import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);

// 全局引入清除样式
import './assets/css/base.css';

// 引入手机网页的调试工具
import Vconsole from 'vconsole'
// 判断环境 测试和开发打开，生产不能打开
if (process.env.NODE_ENV !== 'production') {
  let vConsole = new Vconsole()
  Vue.use(vConsole)
}


// 引入全局组件
import vGoc from './common/index';
for (let attr in vGoc) {
  Vue.component(attr, vGoc[attr]);
}

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
