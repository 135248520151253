<!--
 * @Author: QiTianDaSheng
 * @Date: 2023-02-28 17:35:03
 * @Descripttion: 
 * @LastEditors: QiTianDaSheng
 * @LastEditTime: 2023-08-16 15:09:43
-->
<template>
    <div id="app">
        <!-- 路由出口 -->
        <router-view></router-view>
    </div>
</template>

<script>
export default {
    data() {
        return {}
    },
    methods: {
        // 获取code值
        getUrlKey(name) {
            return (
                decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || ['', ''])[1].replace(/\+/g, '%20')) || null
            )
        },
    },
    created() {
        // 创建完成将获取到的code缓存到本地
        let code = this.getUrlKey('code')
        let type = this.getUrlKey('type')
        if (code) {
            localStorage.setItem('code', code)
        }
        if (type && type == '3') {
            localStorage.setItem('type', '3')
        } else {
            localStorage.setItem('type', '2')
        }
    },
}
</script>

<style></style>
