<template>
    <div>
        <!-- 头部 -->
        <div class="headerBox">
            <i class="goback" @click="goback"></i>
            <h2>{{ $route.meta.title }}</h2>
        </div>
</div>
</template>
<script>

export default {
    data() {
        return {
        }
    },
    components: {
    },
    mounted() {
    },
    methods: {
        // 返回上一级
        goback() {
            this.$router.go(-1);
        }
    },
}
</script>
<style scoped>
.headerBox {
    position: relative;
    width: 750px;
    height: 88px;
    background: #FAFBFD;
    text-align: center;
}

.goback {
    position: absolute;
    top: 30px;
    left: 32px;
    width: 22px;
    height: 22px;
    border-left: 3px solid #333;
    border-top: 3px solid #333;
    transform: rotate(-45deg);
}

.headerBox h2 {
    font-size: 36px;
    font-family: PingFang SC-Bold, PingFang SC;
    font-weight: bold;
    color: #333333;
    line-height: 88px;
}
</style>